'use client';

import '@rainbow-me/rainbowkit/styles.css';
import { BaseProviders, OnramperProvider } from '@sushiswap/ui';
import { QueryClientProvider } from '../../providers/query-client-provider';
import { WagmiProvider } from '../../providers/wagmi-provider';
import { BalanceProvider } from './_common/ui/balance-provider/balance-provider';
import { PriceProvider } from './_common/ui/price-provider/price-provider/price-provider';
export function Providers({
  children,
  cookie
}: {
  children: React.ReactNode;
  cookie: string | null;
}) {
  return <BaseProviders data-sentry-element="BaseProviders" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <OnramperProvider data-sentry-element="OnramperProvider" data-sentry-source-file="providers.tsx">
        <QueryClientProvider data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
          <WagmiProvider cookie={cookie} data-sentry-element="WagmiProvider" data-sentry-source-file="providers.tsx">
            <PriceProvider data-sentry-element="PriceProvider" data-sentry-source-file="providers.tsx">
              <BalanceProvider data-sentry-element="BalanceProvider" data-sentry-source-file="providers.tsx">{children}</BalanceProvider>
            </PriceProvider>
          </WagmiProvider>
        </QueryClientProvider>
      </OnramperProvider>
    </BaseProviders>;
}